import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useState, useEffect } from "react";
import { Trans } from "@lingui/macro";
import CloseIcon from "../../icons/close-icon";
import Calendar from "../calendar";
import theme from "../../theme";
import { getDays, formatDateUtc, dateToDayjs } from "../../utils/dateUtils";
import { isMobile } from "../../utils";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  titleAlignment: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  title: {
    fontWeight: "bold",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  button: {
    width: "150px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
  },
  inputContainer: {
    width: "100%",
    height: 32,
    border: theme.borders[1],
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  calendarIconContainer: {
    borderRight: theme.borders[1],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 35,
    minWidth: 35,
    height: 32,
  },
  datesContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    textTransform: "uppercase",
  },
  calendarIcon: {
    width: 24,
    height: 24,
  },
  rightArrow: {
    transform: "rotate(180deg)",
    width: 21,
    height: 20,
  },
  closeIcon: {
    position: "absolute",
    right: 25,
    cursor: "pointer",
    top: 25,
  },
}));

function DateSelectModal({ open, onClose = () => {}, startDate, endDate, onSelectDates, calendarProps, ...rest }) {
  const classes = useStyles(theme);

  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);

  useEffect(() => {
    if (startDate && endDate) {
      setFilterStartDate(dateToDayjs(startDate).utc().toDate());
      setFilterEndDate(dateToDayjs(endDate).utc().toDate());
      return;
    }
    setFilterStartDate(null);
    setFilterEndDate(null);
  }, [startDate, endDate, open]);

  const handleResetDates = () => {
    setFilterStartDate(null);
    setFilterEndDate(null);
  };

  const handleClose = () => {
    handleResetDates();
    onClose();
  };

  const handleApply = () => {
    onSelectDates(dateToDayjs(filterStartDate).utc().toDate(), dateToDayjs(filterEndDate).utc().toDate());
    onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={isMobile()}
        disableScrollLock
        PaperProps={{
          style: {
            display: "flex",
            justifyContent: "center",
            borderBox: "box-size",
            borderRadius: isMobile() ? "0px" : "10px",
          },
        }}
      >
        <Box className={classes.card}>
          <Box className={classes.closeIcon} onClick={handleClose}>
            <CloseIcon width="15px" height="15px" />
          </Box>
          <Box height="40px" />
          <Box mb={2}>
            <Calendar
              startDate={filterStartDate}
              endDate={filterEndDate}
              onChange={(dates) => {
                setFilterStartDate(dates[0]);
                setFilterEndDate(dates[1]);
              }}
              isDouble
              {...calendarProps}
            />
          </Box>
          <Box width="100%" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Box width="100%" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <Box ml={1}>
                <Typography variant="h6">
                  {`${getDays(formatDateUtc(filterStartDate), formatDateUtc(filterEndDate))} `}
                  <Trans>DÍAS</Trans>
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Box onClick={handleResetDates} style={{ cursor: "pointer" }} display="flex" flexDirection="row" mr={3}>
                  <Typography variant="body1" style={{ textDecoration: "underline" }}>
                    <Trans>Limpiar fechas</Trans>
                  </Typography>
                </Box>
                <Button variant="contained" color="primary" onClick={handleApply}>
                  <Trans>Aplicar</Trans>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default DateSelectModal;
